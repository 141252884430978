.dialog {
  --toggle-border-color: #0079b3;

  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  display: flex;
  flex: 1;
  height: 100vh;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 4;
}

.modal {
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.header {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 56px;
  justify-content: flex-start;
  padding: 0 16px;
}

.toggle {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.toggle::before {
  border-color: var(--toggle-border-color);
  border-style: solid;
  border-width: 0 0 2px 2px;
  content: ' ';
  display: flex;
  height: 12px;
  transform: rotate(45deg);
  width: 12px;
}

.toggle::after {
  color: var(--toggle-border-color);
  content: 'Back';
  margin-left: 0.25rem;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 2rem 1rem 3rem 1rem;
}

.description:not(:last-child) {
  margin-bottom: 2rem;
}

.actions {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  min-height: 48px;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .dialog {
    align-items: center;
    justify-content: center;
  }

  .modal {
    margin: 1rem;
    max-height: calc(100% - 32px);
  }

  .header {
    justify-content: flex-end;
    padding-right: 0;
  }

  .toggle {
    align-items: center;
    height: 48px;
    justify-content: center;
    width: 48px;
  }

  .toggle::before,
  .toggle::after {
    border: none;
    box-shadow: 0 0 0 1px var(--toggle-border-color);
    content: ' ';
    height: 16px;
    margin: 0;
    position: absolute;
    width: 0;
  }

  .toggle::after {
    transform: rotate(-45deg);
  }

  .wrapper {
    padding: 0 56px 56px 56px;
  }
}

@media (min-width: 992px) {
  .modal {
    max-width: 768px;
    width: calc(100% - 32px);
  }
}
