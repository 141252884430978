.pageError {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--content-padding);
}

.icon {
  fill: #ffcd00;
  height: 26px;
  margin-bottom: 2.15rem;
  width: 26px;
}

.header {
  margin-bottom: 1rem;
  text-align: center;
}

.infoMessage {
  text-align: center;
}
