.error {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.icon {
  --iconColor: #FFCD00;

  width: 26px;
  height: 26px;
  padding-bottom: 2.15rem;
  fill: var(--iconColor);
}

.header {
  margin-bottom: 1rem;
  text-align: center;
}

.infoMessage {
  text-align: center;
}
