.companyOption {
  font-family: Roboto;
  padding: 1.2em 0.7em 0.7em;
  border-bottom: 1px solid #e2e8f0;
}

.companyOption.highlighted,
.companyOption:hover {
  background-color: #f9fdff;
  border-bottom-color: #97d6f4;
}

.heading {
  font-weight: 700;
}

.description {
  font-size: 0.875rem;
  color: #475569;
}
