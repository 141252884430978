.header {
  --small: 16px;

  padding-left: var(--small);
  padding-top: var(--small);
  padding-bottom: var(--small);
}

@media (min-width: 768px){
  .header {
    padding-left: 56px;
  }
}
