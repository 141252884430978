.table {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 16px;
  gap: 1rem;
}

.table:not(:last-child) {
  margin-bottom: 32px;
}

.header {
  box-shadow: 0 1px 0 0 #C9CBD9;
  color: #737480;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 1rem;
}

.head {
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.body {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
}

.row {
  display: flex;
}
