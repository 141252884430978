.label {
  color: #737480;
  font-weight: 400;
  margin-bottom: 0.5rem;
  order: -1;
}

.animated {
  position: absolute;
  top: 1.1em;
  left: 1rem;
  pointer-events: none;
  transition: top 150ms ease-in, font-size 150ms ease-in;
  color: #424554;
  font-family: Roboto;
  line-height: normal;
  white-space: nowrap;
  z-index: 1;
}

.error:not(.animated) {
  color: var(--error-text-color);
}

:focus + .animated,
:not(:placeholder-shown):not(:focus) + .animated {
  top: 0.3em;
  font-size: 0.75rem;
}
