.notice {
  --background-color: #f7f8fe;
  --border-color: #c9cbd9;
  --color-slate-grey: #424554;

  align-items: center;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--color-slate-grey);
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  gap: 1rem;
  padding: 16px;
  line-height: 24px;
}

.icon,
.closeIcon {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.closeIcon {
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.wrapper > .title {
  line-height: 26px;
  margin-bottom: 0;
}

.icon > svg {
  fill: var(--border-color);
}

.action {
  --background-color: #f9fdff;
  --border-color: #0079b3;
}

.attention {
  --background-color: #fffef0;
  --border-color: #fde132;
}

.success {
  --background-color: #f9fffc;
  --border-color: #34b576;
}

.error {
  --background-color: #fffafb;
  --border-color: #ce1025;
}
