.textarea {
  --textarea-label-color: #737480;
  --textarea-border-color: #c9cbd9;
  --textarea-border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-family: 'Roboto';
}

.label {
  color: var(--textarea-label-color);
  font-size: 1rem;
  font-weight: 400;
  line-height: 26px;
}

.field {
  font-family: 'Roboto';
  font-size: 1rem;
  resize: none;
  box-shadow: inset 0 0 1px 0 var(--textarea-border-color);
  border-radius: var(--textarea-border-radius);
}
