.paragraph {
  color: #737480;
  font-family: Roboto;
  font-weight: 400;
  margin: 0;
}

.paragraph:not(:last-child) {
  margin-bottom: 16px;
}

.p1 {
  color: #262A35;
  font-size: 1rem;
  line-height: 1.625rem;
}

.p2 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.p3 {
  font-size: 0.75rem;
  line-height: 1rem;
}

.label {
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .p1 {
    color: #424554;
  }
}
