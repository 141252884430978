.wrapper {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.text {
  color: var(--color-dark-gray);
  margin-bottom: 2rem;
  text-align: center;
}

.avatar {
  width: 64px;
  height: 64px;
}

.wrapper .name {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .wrapper {
    flex-direction: row;
    align-items: flex-start;
  }

  .text {
    margin-bottom: 0;
    text-align: unset;
  }

  .section:last-child {
    justify-content: flex-end;
  }

  .section:first-child:last-child {
    justify-content: center;
  }
}
