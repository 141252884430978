.wrapper {
  --field-border-color: #c9cbd9;
  --error-border-color: #ce1025;
  --error-text-color: #ce1025;

  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.wrapper:not(:last-child) {
  padding-bottom: 2rem;
}

.fieldWrapper {
  justify-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.fieldWrapper input[type='checkbox'] {
  appearance: checkbox;
}

.field {
  appearance: none;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px var(--field-border-color);
  flex: 1;
  font-size: 16px;
  padding: 0.75rem 1rem;
}

.animated input {
  font-family: Roboto;
  font-weight: 700;
  padding: 1.1em 1em;
}

.error .field {
  box-shadow: inset 0 0 0 1px var(--error-border-color);
}
