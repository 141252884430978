.detail {
  align-items: flex-start;
  display: flex;
  flex: 1;
  line-height: 26px;
  flex-direction: column;
  gap: 8px;
}

.detail>.label {
  color: #737480;
  font-size: 16px;
}

.detail:not(:last-child) {
  margin-bottom: 16px;
}

.value {
  color: #424554;
  font-weight: 700;
}

@media (min-width: 768px) {
  .detail {
    flex-direction: row;
    gap: 16px;
  }

  .label {
    margin-bottom: 0;
  }
}
