.checkbox {
  --checkbox-border-color: #028dd0;
  --checkbox-check-color: #007dba;
  --checkbox-label-color: #424554;
  --checkbox-outline-color: #bce5f8;

  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.field {
  align-items: center;
  appearance: none;
  border: 2px solid var(--checkbox-border-color);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  outline: none;
  width: 32px;
}

.field:checked {
  align-items: flex-start;
  position: relative;
}

.field:focus {
  outline: 4px solid var(--checkbox-outline-color);
}

.field:checked:before {
  border: solid var(--checkbox-check-color);
  border-width: 0 2px 2px 0;
  content: '';
  display: block;
  height: 14px;
  position: relative;
  top: 4px;
  transform: rotate(45deg);
  width: 6px;
}

.label {
  color: var(--checkbox-label-color);
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  user-select: none;
}
