:root {
    --tooltip-text-color: white;
    --tooltip-background-color: #262838;
    --tooltip-margin: 40px;
    --tooltip-arrow-size: 6px;
    --tooltip-font-size: 14px;
    --tooltip-line-height: 20px;
    --tooltip-max-width: 400px;
}

.tooltip {
    display: flex;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.wrapper:hover::after {
    background-color: var(--tooltip-background-color);
    border-radius: 4px;
    color: var(--tooltip-text-color);
    content: attr(data-tooltip);
    font-family: Roboto;
    font-size: var(--tooltip-font-size);
    font-weight: 400;
    line-height: var(--tooltip-line-height);
    max-width: var(--tooltip-max-width);
    min-width: 200px;
    padding: 12px 16px;
    position: absolute;
    z-index: 1;
}

.wrapper::before {
    content: " ";
    border: solid transparent;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
}

.bottom .wrapper:hover::after {
    top: calc(100% + 8px);
}

.bottom .wrapper:hover::before {
    top: calc(100% - 4px);
    border-bottom-color: var(--tooltip-background-color);
}

.top .wrapper:hover::after {
    bottom: calc(100% + 8px);
}

.top .wrapper:hover::before {
    bottom: calc(100% - 4px);
    border-top-color: var(--tooltip-background-color);
}

.left .wrapper:hover::after {
    right: calc(100% + 12px);
}

.left .wrapper:hover::before {
    right: 100%;
    border-left-color: var(--tooltip-background-color);
}

.right .wrapper:hover::after {
    left: calc(100% + 12px);
}

.right .wrapper:hover::before {
    left: 100%;
    border-right-color: var(--tooltip-background-color);
}
