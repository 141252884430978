.navGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.navGroup::after,
.navGroup:first-of-type::before {
  box-shadow: 0 0 0 0.5px #737480;
  content: ' ';
  margin: 1.5rem 0 2rem 1.625rem;
  width: calc(100% - 96px);
}

.header {
  color: #c9cbd9;
  font-family: 'Roboto';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  margin-bottom: 0.75rem;
  padding-left: 2.25rem;
  text-transform: uppercase;
}

.items {
  display: flex;
  flex-direction: column;
}
