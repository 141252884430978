.wrapper {
  --column-gap: 20px;
  --row-gap: 28px;

  display: flex;
  flex-wrap: wrap;
  gap: var(--row-gap) var(--column-gap);
  padding-bottom: 48px;
}

.linkSection {
  width: 100%;
  word-break: break-word;
}

.linkSection .heading {
  color: #9396A7;
  padding-bottom: 16px;
  text-transform: uppercase;
}

.linkSection > .siteUrl {
  color: #ffffff;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
  text-decoration: none;
  text-align: start;
}

.linkSection > .siteUrl:hover {
  font-weight: 700;
  text-decoration: underline;
  color: #ffffff;
}

@media (min-width: 768px) {
  .wrapper {
    padding-bottom: 40px;
  }

  .linkSection {
    width: calc((100% / 2) - var(--column-gap));
  }
}

@media (min-width: 992px) {
  .linkSection {
    width: calc((100% / 4) - var(--column-gap));
  }
}
