.wrapper {
  --select-error-color: #ce1025;
  --select-label-color: #737480;
  --select-font-size: 16px;
  --select-text-color: #424554;
  --select-placeholder-color: #737480;
  --select-border-color: #c9cbd9;
  --select-border-radius: 4px;

  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.label {
  color: var(--select-label-color);
  font-weight: 400;
  margin-bottom: 8px;
}

.error .label {
  color: var(--select-error-color);
  font-weight: 700;
}

.fieldWrapper {
  align-items: center;
  display: flex;
  position: relative;
}

.field {
  appearance: none;
  border: none;
  border-radius: var(--select-border-radius);
  box-shadow: inset 0 0 0 1px var(--select-border-color);
  cursor: pointer;
  flex: 1;
  font-size: var(--select-font-size);
  padding: 12px 16px;
  color: var(--select-text-color);
}

.placeholder {
  color: var(--select-placeholder-color);
}

.chevron::before,
.chevron::after {
  box-shadow: 0 0 0 1px var(--color-primary);
  content: ' ';
  display: block;
  position: absolute;
  right: 22px;
  transform: rotate(45deg);
  width: 8px;
}

.chevron::after {
  right: 16px;
  transform: rotate(-45deg);
}

.error .field {
  box-shadow: inset 0 0 0 1px var(--select-error-color);
}
