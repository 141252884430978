.radio {
  --radio-label-text-color: #737480;
  --error-border-color: #ce1025;
  --error-text-color: #ce1025;
  --input-border-color: #007dba;
  --input-selected-color: #0079b3;
  --button-variant-border: #c9cbd9;
  --button-variant-border-selected: #009bde;
  --button-variant-background: #eef9ff;
  --button-variant-label-color: #424554;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radio:not(:last-child) {
  margin-bottom: 32px;
}

.label {
  color: var(--radio-label-text-color);
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.error .label {
  color: var(--error-text-color);
  font-weight: 700;
}

.items {
  display: flex;
  gap: 1.5rem;
}

.input {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 16px;
  position: relative;
}

.field {
  appearance: none;
  border-radius: 50%;
  margin: 0;
  outline-color: var(--color-outline);
  outline-offset: 1px;
  padding: 0;
}

.field::before {
  background-color: white;
  border: 1px solid var(--input-border-color);
  border-radius: 50%;
  box-shadow: inset 0 0 0 5px white;
  content: ' ';
  display: block;
  height: 30px;
  width: 30px;
}

.field:checked::before {
  background-color: var(--input-selected-color);
  border-color: var(--input-selected-color);
}

.error .field {
  box-shadow: inset 0 0 0 1px var(--error-border-color);
}

.field + label {
  cursor: pointer;
}

.items.button {
  display: flex;
  flex-direction: column;
}

.button .field {
  outline: none;
}

.button .field::before {
  border-radius: 0.1875rem;
  cursor: pointer;
  height: 48px;
  width: 100%;
  border-color: var(--button-variant-border);
}

.button .field:checked::before {
  background-color: var(--button-variant-background);
  box-shadow: inset 0 0 0 1px var(--button-variant-border-selected);
}

.button .field:checked + label {
  font-weight: 700;
}

.button > label {
  color: var(--button-variant-label-color);
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.button > .field {
  width: 100%;
}
