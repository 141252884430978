.app {
  display: flex;
  flex-direction: column;
}

.app>h2 {
  background-color: lightgray;
  padding: 8px 16px;
}

.demos {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

label {
  color: #9396A7;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 8px;
}

.demos>label:not(:first-child) {
  margin-top: 16px;
}

.demoFlex {
  align-items: center;
  display: flex;
}

.noticeSmallContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.trustpilot {
  background-color: #262838;
}

.navigation {
  background-color: #424554;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 40px;
}

.chip {
  background-color: #262838;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.noticeWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.noticeIcon > svg {
  fill: #d22797;
}

.deprecationBanner {
  background: #fce992;
  box-sizing: border-box;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  padding: 0 1rem;
  width: 100%;
}

.footer {
  background: #424554;
  padding: 0.5rem;
}

@media (min-width: 992px) {
  .checkboxStart > div:first-child {
    align-items: flex-start;
  }
  
  .checkboxBottom > div:first-child {
    align-items: flex-end;
  }

  .navigation {
    width: 282px;
  }
}
