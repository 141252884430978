.heading {
  color: #424554;
  margin: 0;
  word-break: break-word;
}

.heading-1 {
  font-family: "AmsiProCond-Black";
  font-size: 3rem;
  line-height: 3.125rem;
  margin-bottom: 2rem;
}

.heading-1-alt {
  font-family: "Amasis MT Std";
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.heading-2 {
  font-family: "AmsiProCond-Black";
  font-size: 2rem;
  line-height: 2.125rem;
  margin-bottom: 1.5rem;
}

.heading-2-alt {
  font-family: "Amasis MT Std";
  font-weight: 400;
}

.heading-3 {
  font-family: "Amasis MT Std";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 1rem;
}

.heading-4 {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
}

.heading-5 {
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-bottom: 0.25rem;
}

.heading-6 {
  color: #737480;
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 0.25rem;
}

.accent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.accent::after {
  background-color: #FF6B00;
  content: '';
  height: 0.25rem;
  width: 40px;
  border-radius: 4px;
}

.accent-center {
  align-items: center;
}

.heading-1.accent::after {
  margin-top: 2rem;
}

.heading-2.accent::after {
  margin-top: 1.5rem;
}

.heading-3.accent::after {
  margin-top: 1rem;
}

.heading-4.accent::after {
  margin-top: 0.5rem;
}

.heading-5.accent::after {
  margin-top: 0.25rem;
}

@media (min-width: 768px) {
  .heading-1 {
    font-size: 3rem;
    line-height: 3.25rem;
    margin-bottom: 2.5rem;
  }

  .heading-1-alt {
    font-size: 2.75rem;
    line-height: 3.25rem;
  }

  .heading-1.accent::after {
    margin-top: 2.5rem;
  }

  .heading-2 {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
}
