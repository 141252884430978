.errorMessage {
  --error-text-color: #ce1025;
  color: var(--error-text-color);
  font-size: 12px;
  padding: 8px 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Roboto;
}


.note {
  padding: 8px 4px;
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 8px;
}

.error .note {
  color: var(--error-text-color);
}

.errorMessage svg {
  width: 16px;
  height: 16px;
}

.note svg {
  width: 16px;
  height: 16px;
}
