.infoArea {
  background-color: #eef9ff;
  border-radius: 4px;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
}

@media (min-width: 768px) {
  .infoArea {
    padding: 1.5rem 2rem 2.5rem 2rem;
  }
}
