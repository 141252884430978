.navItem {
  --nav-item-text-color: #ffffff;
  --nav-item-disabled-text-color: #c9cbd9;
  --nav-item-bg-color: #262838;
  --notification-color: #e82037;

  align-items: center;
  color: var(--nav-item-text-color);
  display: flex;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  gap: 0.5rem;
  line-height: 20px;
  padding: 0.75rem 0 0.75rem 2.25rem;
  position: relative;
  text-decoration: none;
  user-select: none;
}

.navItem p {
  color: var(--nav-item-text-color);
}

.navItem:hover:not(.disabled) {
  background-color: var(--nav-item-bg-color);
  cursor: pointer;
}

.active {
  font-weight: 700;
}

.active.navItem::before {
  background-color: var(--color-white);
  content: ' ';
  height: 26px;
  left: 0;
  position: absolute;
  width: 4px;
}

.disabled {
  color: var(--nav-item-disabled-text-color);
  pointer-events: none;
}

.disabled::before {
  content: url('../../assets/lock.svg');
  height: 20px;
  margin-left: -2px;
}
