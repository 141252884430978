.chip {
  --chip-color: #ffffff;
  --chip-outline: none;
  --chip-background-color: #c9cbd9;
  --chip-padding: 0.25rem 0.625rem;
  --chip-font-size: 0.6875rem;

  align-items: center;
  align-self: flex-start;
  background-color: var(--chip-background-color);
  border-radius: 3.125rem;
  color: var(--chip-color);
  cursor: default;
  display: flex;
  font-family: 'Roboto';
  font-size: var(--chip-font-size);
  gap: 0.5rem;
  outline: 1px solid var(--chip-outline);
  padding: var(--chip-padding);
}

.filled {
  --chip-color: #000000;
}

.outline {
  --chip-background-color: transparent;
  --chip-color: #ffffff;
  --chip-outline: #c9cbd9;
}

.medium {
  --chip-padding: 0.625rem 0.75rem;
  --chip-font-size: 1rem;
}

.filled.action {
  --chip-color: #ffffff;
  --chip-background-color: #0079b3;
}

.outline.action {
  --chip-color: #0079b3;
  --chip-outline: #0079b3;
}

.filled.attention {
  --chip-color: #ffffff;
  --chip-background-color: #fde132;
}

.outline.attention {
  --chip-color: #fde132;
  --chip-outline: #fde132;
}

.filled.success {
  --chip-color: #ffffff;
  --chip-background-color: #34b576;
}

.outline.success {
  --chip-color: #34b576;
  --chip-outline: #34b576;
}

.filled.error {
  --chip-color: #ffffff;
  --chip-background-color: #ce1025;
}

.outline.error {
  --chip-color: #ce1025;
  --chip-outline: #ce1025;
}
