.action {
  --link-color-primary: #0079b3;
  --link-color-primary-hover: #009bde;

  align-items: center;
  color: var(--link-color-primary);
  display: inline;
  font-weight: 500;
  position: relative;
  text-align: end;
  text-decoration: underline;
}

.action:not(:last-child) {
  margin-bottom: 16px;
}

.action:hover {
  text-decoration: none;
  color: var(--link-color-primary-hover);
}

.external {}

.showIcon {
  display: flex;
}

.showIcon::before,
.showIcon::after {
  border: 1px solid var(--link-color-primary);
  content: ' ';
  order: 1;
  width: 10px;
}

.showIcon::before {
  margin-left: 16px;
}

.showIcon::after {
  border-width: 2px 2px 0 0;
  height: 6px;
  margin-left: -8px;
  transform: rotate(45deg);
  width: 6px;
}

.back {
  padding-right: 0;
  text-align: start;
}

.back::before,
.back::after {
  order: -1;
}

.back::before {
  margin-left: -8px;
  margin-right: 16px;
}

.back::after {
  margin-left: 0;
  order: -2;
  transform: rotate(-135deg);
}
