@keyframes icon-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  display: flex;
  flex-direction: column;
}

.spinner {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: icon-rotate;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  border: 5px solid var(--loading-color);
  border-top-color: transparent;
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.center {
  align-items: center;
  justify-content: center;
}

.core {
  --loading-color: #75227D;
}

.secondary {
  --loading-color: #424554;
}

.business-loans {
  --loading-color: #009BDE;
}

.button {
  --loading-color: #ffffff;
}

.label {
  margin-bottom: 16px;
}
