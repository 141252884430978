.expandableArea {
  --color-primary: #0079b3;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.heading {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  margin-bottom: 0;
}

.title {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 0;
  user-select: none;
}

.chevron {
  border: solid var(--color-primary);
  border-width: 0 3px 3px 0;
  content: '';
  height: 6px;
  transform: rotate(45deg);
  transition: all 0.25s;
  width: 6px;
}

.block {
  flex: 1;
}

.heading > .link {
  align-self: flex-start;
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0;
  text-decoration: underline;
}

.open {
  transform: rotate(-135deg);
}

.content {
  display: flex;
  flex: 1;
  user-select: none;
}
