.currency {
  --bg-color: #f7f8fe;
  --border-color: #c9cbd9;
  --text-color: #424554;

  align-items: center;
  background-color: var(--bg-color);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  bottom: 1px;
  box-shadow: 1px 0 0 0 var(--border-color);
  color: var(--text-color);
  display: flex;
  justify-content: center;
  line-height: 16px;
  margin-left: 1px;
  padding: 0.75rem 0;
  pointer-events: none;
  position: absolute;
  user-select: none;
  width: 48px;
}

.field input {
  padding-left: 64px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
