:root {
  --color-primary: #0079b3;
  --color-primary-hover: #009bde;
  --color-primary-disabled: #97d6f4;
  --color-outline: #bce5f8;
  --color-secondary-pressed: #f9fdff;
  --color-white: #ffffff;
}

.button {
  --primary-bg-active: var(--color-primary);
  --primary-border-active: var(--color-primary);
  --primary-label-active: var(--color-white);
  --primary-bg-hover: var(--color-primary-hover);
  --primary-border-hover: var(--color-primary-hover);
  --primary-label-hover: var(--color-white);
  --primary-bg-focus: var(--color-primary);
  --primary-border-focus: var(--color-primary);
  --primary-label-focus: var(--color-white);
  --primary-outline-focus: var(--color-outline);
  --primary-bg-pressed: var(--color-primary);
  --primary-border-pressed: var(--color-primary);
  --primary-label-pressed: var(--color-white);
  --primary-bg-disabled: var(--color-primary-disabled);
  --primary-border-disabled: var(--color-primary-disabled);
  --primary-label-disabled: var(--color-white);

  --secondary-bg-active: var(--color-white);
  --secondary-border-active: var(--color-primary);
  --secondary-label-active: var(--color-primary);
  --secondary-bg-hover: var(--color-white);
  --secondary-border-hover: var(--color-primary-hover);
  --secondary-label-hover: var(--color-primary-hover);
  --secondary-bg-focus: var(--color-white);
  --secondary-border-focus: var(--color-primary);
  --secondary-label-focus: var(--color-primary);
  --secondary-outline-focus: var(--color-outline);
  --secondary-bg-pressed: var(--color-secondary-pressed);
  --secondary-border-pressed: var(--color-primary);
  --secondary-label-pressed: var(--color-primary);
  --secondary-bg-disabled: var(--color-white);
  --secondary-border-disabled: var(--color-primary-disabled);
  --secondary-label-disabled: var(--color-primary-disabled);

  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  column-gap: 8px;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  line-height: 24px;
  position: relative;
  transition: all .1s ease-out;
}

.button:not(:last-child) {
  margin-bottom: 16px;
}

.primary {
  background-color: var(--primary-bg-active);
  box-shadow: inset 0 0 0 1px var(--primary-border-active);
  color: var(--primary-label-active);
}

.primary:hover:not([disabled]) {
  background-color: var(--primary-bg-hover);
  box-shadow: inset 0 0 0 1px var(--primary-border-hover);
}

.primary:focus:not([disabled]) {
  outline: 4px solid var(--primary-outline-focus);
}



.secondary {
  background-color: var(--secondary-bg-active);
  box-shadow: inset 0 0 0 2px var(--secondary-border-active);
  color: var(--secondary-label-active);
}

.secondary:hover:not([disabled]) {
  box-shadow: inset 0 0 0 2px var(--secondary-border-hover);
  color: var(--secondary-label-hover);
}

.secondary:focus:not([disabled]) {
  outline: 4px solid var(--primary-outline-focus);
}

.link {
  background-color: transparent;
  color: var(--color-primary);
  text-decoration: underline;
}

.link:hover:not([disabled]) {
  color: var(--color-primary-hover);
  text-decoration: none;
}

.link[disabled] {
  background-color: transparent;
  box-shadow: 0 0 0 1px transparent;
}

.link.large,
.link.medium,
.link.small {
  padding: 0;
}

.icon {
  background-color: transparent;
}

.icon.large,
.icon.medium,
.icon.small {
  padding: 0;
}

.icon-left {}

.icon-right {}

.small {
  padding: 4px 16px;
}

.medium {
  padding: 8px 24px;
}

.large {
  font-size: 20px;
  line-height: 24px;
  padding: 12px 24px;
}

.full {
  width: 100%;
}

.loading {
  height: 20px;
  width: 20px;
  border-width: 3px;
}

.button[disabled] {
  background-color: var(--primary-bg-disabled);
  box-shadow: 0 0 0 1px var(--primary-border-disabled);
  color: var(--primary-label-disabled);
  cursor: not-allowed;
}

@media (min-width: 992px) {
  .button:not(:last-child) {
    margin-bottom: 0;
    margin-right: 16px;
  }
}
