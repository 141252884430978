.wrapper {
  position: relative;
}

.field:not(:last-child) {
  padding: 0;
}

.field input {
  padding-right: 3em;
}

.field input ~ .searchIcon {
  position: absolute;
  top: 1.2em;
  right: 1.4em;
  height: 1em;
  width: 1em;
}

.animated .searchIcon {
  top: 1.5em;
}

.clearIcon {
  color: #0079B3;
  position: absolute;
  top: 1.2em;
  right: 1em;
  font-size: 1.4rem;
  cursor: pointer;
}

:disabled ~ .clearIcon {
  cursor: default;
}

.animated .clearIcon {
  top: 0.6em;
}

.menu {
  width: 100%;
  max-height: 13.75em;
  list-style: none;
  padding: 0;
  margin-top: 0.25em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.35);
  overflow-y: auto;
  position: absolute;
  background-color: #FFF;
  z-index: 2;
}
