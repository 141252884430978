.header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.header::after {
  background-color: #ff6b00;
  content: ' ';
  display: block;
  height: 4px;
  margin-top: 16px;
  width: 40px;
}
