.trustpilot {
  --text-color: #ffffff;
  --empty-color: #d7d7e1;
  --star-bg-color: #d7d7e1;

  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0 1rem;
  justify-content: center;
  padding: 16px;
}

.trustpilot .reviews {
  color: var(--text-color);
  margin-bottom: 16px;
}

.trustpilot .label {
  color: var(--text-color);
  font-weight: 700;
}

.trustpilot .logo {
  align-items: flex-end;
  display: flex;
  text-decoration: none;
}

@media (min-width: 768px) {
  .trustpilot {
    flex-direction: row;
    padding: 24px;
  }

  .trustpilot .reviews {
    margin-bottom: 0;
  }
}
